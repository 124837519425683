import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {Button, TopMenu, MenuRow, NavMenu, NavItem, Logo} from 're-cy-cle';
import {Header, Modal, Icon} from 'semantic-ui-react';
import {Route, withRouter} from 'react-router-dom';
import ImgLogo from 'image/logo.svg';
import {SmallAvatar} from 'component/UserAvatar';
import {BUILD_INFO} from 'helpers';

const MyLogo = () => (
    <Logo>
        <img src={ImgLogo} height="35" alt="logo"/>
    </Logo>
);

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderAssets = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.assets.users')}
                    to="/assets/user/overview"
                    activePath="/assets/user/"
                />
                <NavItem
                    title={t('nav.assets.globalValues')}
                    to="/assets/global-value/overview"
                    activePath="/assets/global-value/"
                />
                <NavItem
                    title={t('nav.assets.globalFiles')}
                    to="/assets/global-file/overview"
                    activePath="/assets/global-file/"
                />
            </NavMenu>
        );
    };

    renderAudit = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.audit.accessLog')}
                    to="/audit/access-log/overview"
                    activePath="/audit/access-log/"
                />
            </NavMenu>
        );
    }

    renderAccountMenu = () => {
        const {store} = this.props;
        const {version, branch} = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span>
                        <SmallAvatar user={store.currentUser}/>
                        {" "}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'production' && branch + ' '}
                        {version}
                        )
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <React.Fragment>
                    <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
                </React.Fragment>
            </NavMenu>
        );
    };

    renderEntities = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.entities.entities')}
                    to="/entity/entity/overview"
                />
                <NavItem
                    title={t('nav.entities.structure')}
                    to="/entity/entity-structure/overview"
                />
            </NavMenu>
        );
    }

    renderContracts = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.contract.contract')}
                    to="/contract/contract/overview"
                />
                <NavItem
                    title={t('nav.contract.client')}
                    to="/contract/client/overview"
                />
            </NavMenu>
        );

    }

    render() {
        const {currentUser} = this.props.store;

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return (
                <TopMenu>
                    <MenuRow>
                        <MyLogo/>
                    </MenuRow>
                    <MenuRow/>
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove'/> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark'/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = currentUser.isSuperuser ? (
            <TopMenu>
                <MenuRow>
                    <MyLogo/>
                    <NavMenu>
                        <NavItem
                            title={t('nav.assets.users')}
                            to="/assets/user/overview"
                            activePath="/assets/user/"
                        />
                    </NavMenu>
                    <NavMenu>
                        <NavItem
                            title={t('nav.audit.accessLog')}
                            to="/audit/access-log/overview"
                            activePath="/audit/access-log/"
                        />
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/account" render={this.renderAccount}/>
                    {/* Hackity hack hack to always show second menu bar */}
                    <NavItem title=" " to="/" activePath="/neverusethispath"/>
                </MenuRow>
            </TopMenu>
        ) : (
            <TopMenu>
                <MenuRow>
                    <MyLogo/>
                    <NavMenu>
                        <NavItem
                            title={t('nav.main.assets')}
                            to="/assets/user/overview"
                            activePath="/assets/"
                        />
                        <NavItem
                            title={t('nav.main.contract')}
                            to="/contract/contract/overview"
                            activePath="/contract/"
                        />
                        <NavItem
                            title={t('nav.main.entities')}
                            to="/entity/entity/overview"
                            activePath="/entity/"
                        />
                        <NavItem
                            title={t('nav.audit.accessLog')}
                            to="/audit/access-log/overview"
                            activePath="/audit/access-log/"
                        />
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/account" render={this.renderAccount}/>
                    <Route path="/assets/" render={this.renderAssets}/>
                    <Route path="/audit/" render={this.renderAudit}/>
                    <Route path="/entity/" render={this.renderEntities}/>
                    <Route path="/contract/" render={this.renderContracts} />
                </MenuRow>
            </TopMenu>
        );

        return (
            <React.Fragment>
                {menu}
                {logoutModal}
            </React.Fragment>
        );
    }
}
